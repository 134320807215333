<template>
  <v-dialog
    v-model="isLoading"
    fullscreen
    persistent
    transition="fade-transition"
    content-class="loading"
  >
    <div class="loading-bg">
      <v-progress-circular color="mint" indeterminate />
    </div>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.state.layout.isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  .loading-bg {
    background-color: rgba(33, 33, 33, 0.46);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
